import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Chart from 'vue2-frappe';
import VueGtag from "vue-gtag";
import App from './App.vue';
import Home from './components/pages/Home.vue';
import About from './components/pages/About.vue';
import Faq from './components/pages/Faq.vue';
import StepOne from './components/steps/StepOne';
import StepTwo from './components/steps/StepTwo';
import StepThree from './components/steps/StepThree';
import StepFour from './components/steps/StepFour';
import StepFive from './components/steps/StepFive';
import StepSix from './components/steps/StepSix';
import Wrapper from './components/includes/Wrapper';
import Results from './components/steps/Results';
import Copyright from './components/pages/Copyright';
import PrivacyStatement from './components/pages/PrivacyStatement';
import i18n from "@/i18n";
import 'bootstrap/scss/bootstrap.scss';

import './less/theme.less';
import './js/scripts.js';

require('bootstrap');

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Chart);

const router = new VueRouter({
    base:                 '/vvt/',
    routes:               [
        {
            path:       '/',
            components: {
                main: Wrapper,
                body: Home
            },
            children:   [{
                path:       '',
                name:       'home',
                components: {
                    body: Home
                }
            }, {
                path:       '/stap-1',
                alias:      ['/step-1', '/steg-1', '/1-lepes', '/paso-1', '/etape-1', '/schritt-1', '/zingsnis-1', '/trin-1'],
                name:       'stepOne',
                components: {
                    body: StepOne
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }, {
                path:       '/stap-2',
                alias:      ['/step-2', '/steg-2', '/2-lepes', '/paso-2', '/etape-2', '/schritt-2', '/zingsnis-2', '/trin-2'],
                name:       'stepTwo',
                components: {
                    body: StepTwo
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }, {
                path:       '/stap-3',
                alias:      ['/step-3', '/steg-3', '/3-lepes', 'paso-3', '/etape-3', '/schritt-3', '/zingsnis-3', '/trin-3'],
                name:       'stepThree',
                components: {
                    body: StepThree
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }, {
                path:       '/stap-4',
                alias:      ['/step-4', '/steg-4', '/4-lepes', '/paso-4', '/etape-4', '/schritt-4', '/zingsnis-4', '/trin-4'],
                name:       'stepFour',
                components: {
                    body: StepFour
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }, {
                path:       '/stap-5',
                alias:      ['/step-5', '/steg-5', '/5-lepes', '/paso-5', '/etape-5', '/schritt-5', '/zingsnis-5', '/trin-5'],
                name:       'stepFive',
                components: {
                    body: StepFive
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }, {
                path:       '/stap-6',
                alias:      ['/step-6', '/steg-6', '/6-lepes', '/paso-6', '/etape-6', '/schritt-6', '/zingsnis-6', '/trin-6'],
                name:       'stepSix',
                components: {
                    body: StepSix
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }, {
                path:       '/resultaten',
                alias:      ['/results', '/resultat', '/eredmenyek', '/resultados', '/resultats', '/ergebnisse', '/rezultatai', '/resultater'],
                name:       'results',
                components: {
                    body: Results
                },
                meta:       {
                    requiresFirstFieldFilledIn: true
                }
            }]
        }, {
            path:       '/over-de-tilthermometer',
            alias:      ['/about-the-tilthermometer', '/om-tilthemometer', '/a-tilthermometer-rol', '/acerca-del-tilthermometer', '/a-propos-du-tilthermometer', '/hintergrundinformationen-des-tilthermometers', '/apie-tilthermometer', '/om-tilThermometer'],
            name:       'about',
            components: {
                main: About
            }
        }, {
            path:       '/copyright',
            name:       'copyright',
            components: {
                main: Copyright
            }
        }, {
            path:       '/privacyverklaring',
            alias:      ['/privacy-statement', '/privatumo-pareiskimas', '/privatlivspolitik'],
            name:       'privacyStatement',
            components: {
                main: PrivacyStatement
            }
        }, {
            path:       '/veel-gestelde-vragen',
            alias:      ['/frequently-asked-questions', '/vanliga-fragor', '/gyakran-ismetelt-kerdesek', '/preguntas-frecuentes', '/questions-frequemment-posees', '/haufig-gestellte-fragen', '/dazniausiai-uzduodami-klausimai', '/ofte-stillede-spørgsmål'],
            name:       'frequentlyAskedQuestions',
            components: {
                main: Faq
            }
        }
    ],
    mode:                 'history',
    linkExactActiveClass: 'active',
});

Vue.config.productionTip = false;

const store = new Vuex.Store({
    state:     {
        organizationName: '',
        departmentName:   '',
        amountClients:    0,
        contactName:      '',
        questions:        [
            {id: '1.1.a', value: 0},
            {id: '1.1.b', value: 0},
            {id: '1.1.c', value: 0},
            {id: '1.1.d', value: 0},
            {id: '1.1.e', value: 0},
            {id: '1.1.f', value: 0},

            {id: '1.2.a', value: 0},
            {id: '1.2.b', value: 0},
            {id: '1.2.c', value: 0},
            {id: '1.2.d', value: 0},
            {id: '1.2.e', value: 0},
            {id: '1.2.f', value: 0},

            {id: '1.3.a', value: 0},
            {id: '1.3.b', value: 0},
            {id: '1.3.c', value: 0},
            {id: '1.3.d', value: 0},
            {id: '1.3.e', value: 0},
            {id: '1.3.f', value: 0},

            {id: '2.1.a', value: 0},
            {id: '2.1.b', value: 0},
            {id: '2.1.c', value: 0},
            {id: '2.1.d', value: 0},
            {id: '2.1.e', value: 0},
            {id: '2.1.f', value: 0},

            {id: '2.2.a', value: 0},
            {id: '2.2.b', value: 0},
            {id: '2.2.c', value: 0},
            {id: '2.2.d', value: 0},
            {id: '2.2.e', value: 0},
            {id: '2.2.f', value: 0},

            {id: '2.3.a', value: 0},
            {id: '2.3.b', value: 0},
            {id: '2.3.c', value: 0},
            {id: '2.3.d', value: 0},
            {id: '2.3.e', value: 0},
            {id: '2.3.f', value: 0},

            {id: '3.1', value: 0},
            {id: '3.2', value: 0},

            {id: '4.1', value: 0},
            {id: '4.2', value: 0},

            {id: '5.1', value: 0},
            {id: '5.2', value: 0},

            {id: '6.1', value: 0},
            {id: '6.2', value: 0},
            {id: '6.3', value: 0},
            {id: '6.4', value: 0},
            {id: '6.5', value: 0},
            {id: '6.6', value: 0},
            {id: '6.7', value: 0},
            {id: '6.8', value: 0},
            {id: '6.9', value: 0},
            {id: '6.10', value: 0}
        ]
    },
    getters:   {
        getQuestionById: (state) => (id) => {
            return state.questions.find(question => question.id === id);
        }
    },
    mutations: {
        initialiseStore(state) {
            if(sessionStorage.getItem('store')) {
                this.replaceState(
                    Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
                );
            }
        },
        setOrganizationName(state, organizationName) {
            state.organizationName = organizationName;
        },
        setDepartmentName(state, departmentName) {
            state.departmentName = departmentName;
        },
        setAmountClients(state, amountClients) {
            state.amountClients = parseInt(amountClients);
        },
        setContactName(state, contactName) {
            state.contactName = contactName;
        },
        setQuestion(state, payload) {
            for(let index in state.questions) {
                if(state.questions[index].id === payload.id) {
                    state.questions[index].value = payload.value;
                }
            }
        }
    }
});
store.subscribe((mutation, state) => {
    sessionStorage.setItem('store', JSON.stringify(state));
});

Vue.use(VueGtag, {
    config: {id: "UA-40339467-8"}
}, router);

new Vue({
    router,
    store,
    i18n,
    beforeCreate() {
        this.$store.commit('initialiseStore');
    },
    render: h => h(App)
}).$mount('#tilthermometer-vvt');
